import React from "react"
import { makeStyles } from "@material-ui/core/styles";
import { Button, Container, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    main:{
        marginTop:"40px",
        marginBottom:"40px"
    },
    surveyTitle:{
        paddingBottom: "10px"
    },
    line:{
        marginTop: "-10px",
        marginBottom: "20px"
    },
    surveyDescription:{
        lineHeight: 1.5,
        textAlign: "justify"
    },
    surveyButton:{
        margin: "15px auto",
        display:"flex",
        width: "200px",
        fontSize: "20px",
        textTransform: "none",
        "@media screen and (min-width: 768px)": {
            margin: "25px auto",
          },
    }
}))

export default function Survey({data}){
    const classes = useStyles();
    return(
        <Container className={classes.main}>
            <Typography variant="h4" color="primary" className={classes.surveyTitle}
            >{data.title}</Typography>
            <hr color="#2196f3" className={classes.line}/>
            <Typography variant="body2" className={classes.surveyDescription}
            >{data.description}</Typography>
            <Button 
            variant="contained"
            color="primary"
            href="https://forms.gle/pUX2z4MtVrtAwwer7" target="_blank"
            className={classes.surveyButton}
            >Phiếu khảo sát</Button>
        </Container>
    )
}