import React from "react";
import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Alink from "../../common/Alink"

const useStyles = makeStyles((theme) => ({
    main: {
        paddingTop: "40px",
    },
    supportTitle: {
        marginBottom: "10px"
    },
    line: {
        marginTop: "-10px",
        marginBottom: "20px"
    },
    supportContent: {
        textAlign: "justify",
        margin: "10px auto",
    },
    link: {
        textDecoration: "none",
        color: "inherit",
        "&:hover": {
            color: "#488BE9",
        },
    }
}))

export default function Support({ data }) {
    const classes = useStyles();
    return (
        <Container className={classes.main}>
            <Typography variant="h4" color="primary" className={classes.supportTitle}>
                {data.title}
            </Typography>
            <hr color="#2196f3" className={classes.line} />
            {data.contents.map((item) => {
                return (
                    <Typography variant="body2" className={classes.supportContent}>
                        <Alink className={classes.link} to={`/help/${item.id}`}>
                            {item.question}
                        </Alink>
                    </Typography>
                )
            })}
        </Container>
    )
}