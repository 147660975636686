import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Support from "../sections/Support";
import Survey from "../sections/Survey";
import Contact from "../sections/Contact";
import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  help: { backgroundColor: "white" },

  main: {
    minHeight: "calc( 100vh - 56px)",
    paddingBottom: "477px",
    transition: "all .3s ease-in-out",
    [theme.breakpoints.up("sm")]: {
      paddingBottom: "230px",
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: "233px",
    },
    "@media screen and (max-width: 356px)": {
      paddingBottom: "496px",
    },
    display: "flex",
    width: "100%",
    maxHeight: "auto",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  box: {
    width: "33.33333%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

export default function Help({ data }) {
  const classes = useStyles();
  return (
    <div className={classes.help}>
      <Container className={classes.main}>
        <div className={classes.box}>
          <Support data={data.Support} />
        </div>
        <div className={classes.box}>
          <Contact />
        </div>
        <div className={classes.box}>
          <Survey data={data.Survey} />
        </div>
      </Container>
    </div>
  );
}
