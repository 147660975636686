import React, { useState } from "react";
import { Button, Container, makeStyles, TextField, Typography } from "@material-ui/core";
import InputAdornment from '@material-ui/core/InputAdornment';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import { useFormik } from 'formik';
import * as yup from 'yup';

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: "40px",
    marginBottom: "40px"
  },
  row: {
    margin: "15px 0",
  },
  textfield: {
    width: "100%",
  },
  line: {
    marginTop: "0px",
    marginBottom: "30px"
  },
  contactButton: {
    margin: "15px auto",
    display: "flex",
    width: "200px",
    fontSize: "20px",
    textTransform: "none",
    "@media screen and (min-width: 768px)": {
      margin: "25px auto",
    },
  },
  errors: {
    color: '#FF0000',
    margin: '0.5rem 0 0 0.5rem',
    fontSize: "14px",
  }
}))

export default function Contact({ data }) {
  const classes = useStyles();
  const [state, setState] = useState({
    displayName: "",
    email: "",
    phone: "",
  });

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const validationSchema = yup.object({
    displayName: yup
      .string()
      .required("Hãy nhập họ tên"),
    email: yup
      .string()
      .email("Format email không đúng")
      .required("Hãy nhập email"),
    phone: yup
      .string()
      .matches(phoneRegExp, "Số điện thoại không đúng")
      .min(10, "Số điện thoại không đúng")
      .max(13, "Số điện thoại không đúng")
      .required("Hãy nhập số điện thoại"),
  });

  const changeName = (e) => {

    const new_e = {
      target: {
        name: e.target.id,
        value: e.target.value
      }
    };
    return formik.handleChange(new_e)
  }

  const formik = useFormik({
    initialValues: {
      ...state
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      document.forms.entry.submit();
      let { displayName, email, phone } = values
      setState(prevState => ({
        ...prevState,
        displayName: displayName,
        email: email,
        phone: phone,
      }));
    },
  })

  return (
    <Container className={classes.main}>
      <Typography variant="h4" color="primary" className={classes.title}>Liên hệ</Typography>
      <hr color="#2196f3" className={classes.line} />
      <form className={classes.formContact} onSubmit={formik.handleSubmit} name="entry" action="https://docs.google.com/forms/u/1/d/e/1FAIpQLSfyZW4pg9sWezC0RfzTwgkooLzJNBqW8Z0olZMCFGfvG7azQg/formResponse">
        <div className={classes.row}>
          <TextField
            className={classes.textfield}
            id="displayName"
            name="entry.1717294309"
            placeholder="Họ và tên *"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
            value={formik.values.displayName}
            onChange={changeName}
          />
          {formik.errors.displayName && formik.touched.displayName && (
            <div className={classes.errors}>{formik.errors.displayName}</div>
          )}
        </div>
        <div className={classes.row}>
          <TextField
            className={classes.textfield}
            id="email"
            name="entry.11884556"
            placeholder="Email *"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
            value={formik.values.email}
            onChange={changeName}
          />
          {formik.errors.email && formik.touched.email &&
            (<div className={classes.errors}>{formik.errors.email}</div>)}
        </div>
        <div className={classes.row}>
          <TextField
            className={classes.textfield}
            id="phone"
            name="entry.1338339259"
            placeholder="Phone *"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneIcon />
                </InputAdornment>
              ),
            }}
            value={formik.values.phone}
            onChange={changeName} />
          {formik.errors.phone && formik.touched.phone &&
            (<div className={classes.errors}>{formik.errors.phone}</div>)}
        </div>
        <div className={classes.row}>
          <TextField
            className={classes.textfield}
            multiline
            id="description"
            name="entry.1380329616"
            placeholder="Nội dung thắc mắc"
            variant="outlined"
            rows={4}
            value={formik.values.description}
            onChange={changeName} />
        </div>
        <Button variant="contained" color="primary" type="submit" className={classes.contactButton}>Gửi ý kiến</Button>
      </form>
    </Container>
  )
}