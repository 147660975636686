import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/shell/seo"
import Layout from "../components/shell/layout"
import Help from "../components/help/pageContents/Help"

const HelpPage = ({ data }) => (
  <Layout>
    <SEO title="Help" />
    <Help data={data.helpJson} />
  </Layout>
)
export default HelpPage

export const query = graphql`
  {
    helpJson {
      Support {
        title
        contents {
          id
          question
          answer {
            sub_description
            sub_title
            image {
              alt
              src {
                childImageSharp {
                  fluid {
                    srcSet
                  }
                }
              }
            }
          }
        }
      }
      Survey {
        description
        title
      }
    }
  }
`
